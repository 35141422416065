import Lenis from "@studio-freight/lenis";

// by convention, composable function names start with "use"
export function useLenis() {
    
    const $body = document.body
    let scrolled = 0
    let scrolled_percentage = 0
    let breakpoint = 20
    
    function setScrolledPercentage(){
        $body.style.setProperty('--scrolled_percentage', scrolled_percentage);
    }
    
    const lenis = new Lenis({
        content: document.documentElement,
        duration: 1.2,
        easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
        gestureOrientation: 'vertical',
        //lerp: 0.1,
        orientation: 'vertical',
        smoothWheel: true,
        infinite: false,
        wrapper: window
    });
    
    setScrolledPercentage()
    
    lenis.on('scroll', (e) => {
        
        scrolled = e.animatedScroll
        scrolled_percentage = 100 * (scrolled / ($body.clientHeight - window.innerHeight))
        setScrolledPercentage()
        if(scrolled > breakpoint){
            if(!$body.classList.contains('below_breakpoint')){
                $body.classList.add('below_breakpoint');
            }            
        } else {
            if($body.classList.contains('below_breakpoint')){
                $body.classList.remove('below_breakpoint');
            }            
        }
        
    })

    function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
    
}