<template>
    <section class="about">
        <div class="inner">
            <h1>About</h1>
        </div>
    </section>
</template>

<script>
    export default {
        
        components: {},
        
        data() {
            return {}
        },
        
        created() {},
        
        methods: {}
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    section.about {
        min-height: 200vh;
    }
</style>