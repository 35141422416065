<template>
    <div id="background" ref="background"></div>    
</template>

<script>
    
    export default {
        name: 'PageBackground',        
        // Properties returned from data() become reactive state
        // and will be exposed on `this`.
        data() {
            return {
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight
            }
        },        
        props: {},        
        // Methods are functions that mutate state and trigger updates.
        // They can be bound as event listeners in templates.
        methods: {  
            onResize() {
                this.windowWidth = window.innerWidth
                this.windowHeight = window.innerHeight
            }
        },
        
        // Lifecycle hooks are called at different stages of a component's lifecycle.
        // This function will be called when the component is mounted.        
        mounted() {
            this.background = this.$refs.background;
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
            })
        },
        beforeUnmount() {
            // the listener is on the window, not the component that gets destroyed
            window.removeEventListener('resize', this.onResize); 
        }
        
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #background { 
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
        user-select: none;
        background-color: #2e2e2e;
    }
</style>