import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/PageHome.vue";
import About from "@/views/PageAbout.vue";
import NotFound from "@/views/PageNotFound.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/about",
        name: "About",
        component: About
    },
    {
        //path: "/:notFound",
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: NotFound
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: 'current',
    scrollBehavior() {
        return {
            left: 0,
            top: 0,
            behavior: 'smooth'
        }        
    },
});

export default router;