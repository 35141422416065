<template>
    <div id="scrolled"></div>
</template>

<script>    
    export default {
        name: 'PageScrolled',        
        // Properties returned from data() become reactive state
        // and will be exposed on `this`.
        data() {
            return {}
        },        
        props: {},        
        // Methods are functions that mutate state and trigger updates.
        // They can be bound as event listeners in templates.
        methods: {},
        
        // Lifecycle hooks are called at different stages of a component's lifecycle.
        // This function will be called when the component is mounted.        
        mounted() {},
        beforeUnmount() {}
        
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #scrolled { 
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        height: 2px;
        z-index: 0;
        pointer-events: none;
        user-select: none;
        mix-blend-mode: difference;
    }
    #scrolled:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
        user-select: none;
        background-color: #fff;
        transition: transform 60ms ease;
        transform: translate3d(calc(1% * (-100 + var(--scrolled_percentage))), 0, 0);
    }
</style>