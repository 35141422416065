<template>
    <div class="sections">
        <section class="hero" data-inview data-bghexcolor="#2e2e2e" data-logohexcolor="#dddddd">
            <h1 ref="hero_title">ansvarlig marketing<br>og kommunikation</h1>
        </section>
        <section class="statement" data-inview data-bghexcolor="#fafafa" data-logohexcolor="#dddddd">
            <div class="items">
                <div class="item" data-inview>
                    <div class="title">
                        <h2>Hvad mener vi med ansvarlig marketing?</h2>
                    </div>
                    <div class="copy">
                        <p>Det handler i bund og grund om tillid. For vi ved godt, det kan være overvældende at overlade ansvaret for dine marketingaktiviteter til en ekstern leverandør. Det er blandt andet derfor, vi gør tingene lidt anderledes.</p>
                        <p>Når du vælger os, vælger du et samarbejde baseret på gensidig tillid, hvor engagement, seriøsitet og transparens er en selvfølge. For vi påtager os et stort ansvar, når vi siger, at vi kan skabe værdi for dig og dine kunder. Og det ansvar tager vi meget seriøst.</p>
                    </div>
                </div>
                <div class="item" data-inview>
                    <div class="title">
                        <h2>Hvordan gør vi?</h2>
                    </div>
                    <div class="copy">
                        <p>Et samarbejde starter med, at vi lærer hinanden at kende. Vi skal fx have en god forståelse for din virksomheds værdier, dine produkter eller ydelser og din målgruppe. Og det skal du hjælpe os med.</p>
                        <p>Lidt forenklet kan du sige, at det handler mere om dine kunder, end det handler om dig – altså dine kunders behov, deres ønsker og deres adfærd. Udfordringen er, hvordan dit produkt eller ydelse kan opfylde disse behov, og ikke mindst hvordan og hvor, du fortæller om det. Det er dét, vi kan hjælpe dig med.</p>
                        <p>Derfor stiller vi også mange spørgsmål – især i starten: Hvordan oplever kunderne din hjemmeside? Kan de forstå dine annoncer og føle dit budskab helt ned i maven? Har de et smil på læben og produkter i kurven, når de har besøgt din butik? Og kommer de gerne igen?</p>
                        <p>Det er bare nogle af de ting, vi sammen skal dykke ned i, inden vi zoomer ud og kortlægger den rute, der vil give bedst mening for din virksomhed.</p>
                    </div>
                </div>
                <div class="item" data-inview>
                    <div class="title">
                        <h2>En målsætning der forpligter.</h2>
                    </div>
                    <div class="copy">
                        <p>Når kursen er sat, udarbejder vi en klar målsætning og en strategi, der fortæller, hvor I er på vej hen, og hvad I vil opnå. Strategien er fundamentet for den marketingplan, vi opbygger og udvikler på sigt.</p>
                        <p>Vores tilgang er både visionær og innovativ, og du vil helt sikkert opleve, at vi udfordrer dig undervejs. Derfor har du hele tiden en klar indsigt i, hvem der gør hvad, hvornår og hvorfor. På den måde kan du altid følge, hvordan de enkelte tiltag og/eller kampagner virker, og hvilke justeringer vi gør undervejs. Og du har selvfølgelig altid det sidste ord i forhold til de budskaber, vi sender ud i verden.</p>
                        <p>Vi tror nemlig på, at et succesfuldt samarbejde går begge veje.</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="services" data-inview data-bghexcolor="#c9c08e" data-logohexcolor="#dddddd">
            <div class="items">
                <div class="item">
                    <div class="title">
                        <h2>Vores kerneydelser.</h2>
                    </div>
                </div>
                <div class="item">
                    <div class="copy">
                        <p>Takket være de digitale platforme har mulighederne for at gøre sig synlig og ikke mindst interessant for sin målgruppe aldrig været større.</p>
                        <p>Set fra et marketingperspektiv er platforme som fx Facebook, Instagram og Google særligt effektive, fordi du med algoritmernes hjælp kan ramme dit publikum meget præcist og hele tiden kan måle effekten af dine handlinger. Samtidig kan du følge din målgruppes adfærd hele vejen – fra interesse til konvertering. </p>
                        <p>Det kræver dog både tid og kendskab til de værktøjer og muligheder, de forskellige platforme byder på. Og det er her, vi kommer ind i billedet. </p>
                        <p>Vi hjælper nemlig primært startups, mindre virksomheder og kunst-, kultur- og nonprofitorganisationer, som ikke selv har volumen til at etablere en egentlig marketingafdeling. De ved, det er afgørende at være til stede på de digitale platforme, men har valgt at overdrage denne del til os, så de kan fokusere 100 % på deres kerneforretning.</p>
                        <p>Og selvom vi arbejder med en lang række digitale marketingkanaler, insisterer vi på at fordybe og engagere os i din virksomhed/din forretning, dine behov og dine mål. Kun på den måde kan vi med sikkerhed skabe den helt rigtige marketingløsning for dig og din virksomhed.</p>
                        <p>Derfor har vi samlet en lille oversigt over de initiativer, vi i fællesskab skal overveje og tage stilling til, når vi sammensætter din marketingplan.</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="areasofexpertise" data-inview data-bghexcolor="#dddddd" data-logohexcolor="#ffffff">
            <div class="items">
                <div class="item collapsed" @click.prevent="toggleCollapsed">
                    <div class="title">
                        <h2>Paid Social</h2>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
                            <path class="st0" d="M9.3820543,10.6182423H6.3583984V9.3822994h3.0236559V6.3585205h1.2358913v3.0237789h3.0236559v1.2359428 h-3.0236559v3.0232372H9.3820543V10.6182423z"/>
                        </svg>
                    </div>
                    <div class="copy">
                        <p>Paid social betyder faktisk bare betalte ”annoncer” på sociale medier som fx Facebook og Instagram. Grunden til, at Paid social er så populært er, at du har mulighed for at målrette dit budskab til en meget specifik målgruppe. Ved hjælp af den enorme indsigt, som fx Facebook har om brugernes adfærd kan du med betalt annoncering derfor synliggøre dit produkt eller din ydelse for lige præcis dem som – måske uden selv at vide det – går og drømmer om det.</p>
                        <p>Arbejdet med Paid social inkluderer alt fra planlægning, strategi til eksekvering og optimering og er derfor en omfattende disciplin, der konstant skal overvåges og vedligeholdes, for at være en god forretning.</p>
                    </div>
                </div>
                <div class="item collapsed" @click.prevent="toggleCollapsed">
                    <div class="title">
                        <h2>Google Ads</h2>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
                            <path class="st0" d="M9.3820543,10.6182423H6.3583984V9.3822994h3.0236559V6.3585205h1.2358913v3.0237789h3.0236559v1.2359428 h-3.0236559v3.0232372H9.3820543V10.6182423z"/>
                        </svg>
                    </div>
                    <div class="copy">
                        <p>Google Ads er Googles annonce-platform. Med Google Ads har du mulighed for at lave annoncer til brugere, der søger på bestemte søgeord eller har en bestemt søgeadfærd. Med Google Ads bliver du derfor synlig for mennesker, der er interesserede i netop de produkter eller ydelser, du tilbyder. Gode søgeannoncer handler derfor om at vise, at netop du er mere relevant og interessant end dine konkurrenter og derved skabe trafik til din hjemmeside.</p>
                        <p>Med Google Ads er selve visningen af din annonce gratis. Du betaler kun, når en bruger klikker på din annonce – altså en betaling per klik. Det er både en smart og udfordrende annoncemodel, for det er relativt nemt at komme i gang, men desværre også utrolig nemt at brænde alt for mange penge af på dyre fælder og ineffektiv markedsføring.</p>
                    </div>
                </div>
                <div class="item collapsed" @click.prevent="toggleCollapsed">
                    <div class="title">
                        <h2>Organic Content</h2>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
                            <path class="st0" d="M9.3820543,10.6182423H6.3583984V9.3822994h3.0236559V6.3585205h1.2358913v3.0237789h3.0236559v1.2359428 h-3.0236559v3.0232372H9.3820543V10.6182423z"/>
                        </svg>
                    </div>
                    <div class="copy">
                        <p>Sociale medier er på ingen tid blevet den måde, vi mennesker primært kommunikerer på. Når vi taler om organic marketingcontent, handler det med andre ord om at skabe et univers, der giver mening for både din virksomhed og dine potentielle følgere. Du skal beslutte dig for, hvem du er (eller gerne vil være) og derfra opbygge en helt klar strategi for din tone of voice – altså den måde, du fortæller din historie på. Med en stærk og overbevisende tone of voice har du nemlig en unik mulighed for at skille dig ud fra mængden – jo større indtryk dit budskab gør, jo længere tid vil du være i målgruppens bevidsthed.</p>
                        <p>Samtidig skal du have kortlagt din målgruppe, da målgruppens profil og adfærd er essentiel for opbygningen af en langsigtet contentplan – altså, hvordan dit indhold skal udarbejdes, hvor det skal være, og hvor tit det skal udkomme.</p>
                    </div>
                </div>
                <div class="item collapsed" @click.prevent="toggleCollapsed">
                    <div class="title">
                        <h2>E-mail Marketing</h2>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
                            <path class="st0" d="M9.3820543,10.6182423H6.3583984V9.3822994h3.0236559V6.3585205h1.2358913v3.0237789h3.0236559v1.2359428 h-3.0236559v3.0232372H9.3820543V10.6182423z"/>
                        </svg>
                    </div>
                    <div class="copy">
                        <p>Vi har den holdning, at mennesker køber af mennesker, og contentbaseret e-mail marketing handler primært om at skabe relationer og loyalitet gennem nyhedsbreve. Derfor skal dit nyhedsbrev ikke kun omhandle det produkt eller den ydelse, du gerne vil sælge – og det er uanset, om din målgruppe tilhører B2B- eller B2C-segmentet.</p>
                        <p>Det kræver både segmentering, planlægning og relevant content at gøre sig fortjent til en plads i målgruppens indbakke, så det handler både om at udtænke og tænke langsigtet. Gør du det, er e-mail marketing den ideelle mulighed for at servere mest mulig relevans og værdi til dine målgrupper.</p>
                    </div>
                </div>
                <div class="item collapsed" @click.prevent="toggleCollapsed">
                    <div class="title">
                        <h2>User Experience og Conversion Rate Optimization</h2>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
                            <path class="st0" d="M9.3820543,10.6182423H6.3583984V9.3822994h3.0236559V6.3585205h1.2358913v3.0237789h3.0236559v1.2359428 h-3.0236559v3.0232372H9.3820543V10.6182423z"/>
                        </svg>
                    </div>
                    <div class="copy">
                        <p>Disse to begreber relaterer sig primært om dine hjemmeside, hvor User Experience handler om selve brugeroplevelsen, mens Conversion Rate Optimization handler om at påvirke brugerne til at foretage en bestemt handling.</p>
                        <p>User Experience repræsenterer den oplevelse en potentiel kunde har, når han eller hun besøger din hjemmeside. Mange har derfor fokus på at optimere hjemmesidens struktur, design og indhold, men glemmer, at en god kundeoplevelse ikke nødvendigvis lig med konvertering. Det er nemlig typisk på din hjemmeside, kunden laver den endelige konvertering. </p>
                        <p>Du skal derfor have styr på både User Experience og Conversion Rate Optimization, hvis din hjemmeside skal fungere optimalt.</p>
                        <p>Og så må du ikke glemme, at en kunderejse sjældent begynder på din hjemmeside, men på sociale medier, i søgemaskiner eller via et nyhedsbrev. Kunderejsen er med andre ord de skridt og handlinger, dine potentielle kunder foretager både før, under og efter, de køber et produkt eller en ydelse.</p>
                        <p>En kortlægning af kunderejsen er derfor et essentielt værktøj, som skaber overblik over, hvor og hvordan du (og din virksomhed) hjælper dine kunder bedst.</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="contact" data-inview data-bghexcolor="#2e2e2e" data-logohexcolor="#dddddd">
            <h1>kontakt os</h1>
            <div class="buttons">
                <div class="button">
                    <a href="tel:+4528760675‬">
                        <h2>+45 2876 0675‬</h2>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
                            <path class="st1" d="M9.895752,13.7011719l3.1533203-3.1538086H4.8815918V9.4526367h8.1674805L9.895752,6.309082 l0.7661133-0.7661133L15.1184082,10l-4.456543,4.4570312L9.895752,13.7011719z"/>
                        </svg>
                    </a>
                </div>
                <div class="button">
                    <a href="mailto:info@duckert.agency">
                        <h2>info@duckert.agency</h2>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
                            <path class="st1" d="M9.895752,13.7011719l3.1533203-3.1538086H4.8815918V9.4526367h8.1674805L9.895752,6.309082 l0.7661133-0.7661133L15.1184082,10l-4.456543,4.4570312L9.895752,13.7011719z"/>
                        </svg>
                    </a>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { store } from '../store.js'
    import { gsap } from 'gsap';
    import { ScrollTrigger } from 'gsap/ScrollTrigger';
    gsap.registerPlugin(ScrollTrigger);

    export default {
        
        components: {},
        
        data() {
            return {
                store,
                windowHeight: window.innerHeight,
                hero_title: null,
                logo_height: null
            }
        },
        
        created() {},      
        methods: {
            toggleCollapsed(event) {
                console.log('toggleCollapsed');
                event.target.closest('.item').classList.toggle('collapsed');
            },
            initHeroTitle(){
                
                const sections = gsap.utils.toArray('main .sections section');
                
                gsap.set(
                    this.hero_title,
                    {
                        opacity: 1
                    }
                );

                gsap.timeline(
                    {
                        scrollTrigger: {
                            trigger: sections[0],
                            start: "top top",
                            end: "bottom 75%",
                            scrub: true,
                            markers: false
                        }
                    }
                ).to(
                    this.hero_title,
                    {
                        ease: 'none',
                        opacity: 0
                    }
                );
                
            },
            initStatementItem(item){
                
                //item.style.border = '1px solid red'
                
                const title = item.querySelector('.title');
                const copy = item.querySelector('.copy');
                
                gsap.set(
                    title,
                    {
                        y: window.innerHeight * 0.5
                    }
                );
                
                gsap.set(
                    copy,
                    {
                        y: window.innerHeight * 0.5
                    }
                );

                let tl = gsap.timeline(
                    {
                        scrollTrigger: {
                            trigger: item,
                            start: "top 125%",
                            end: "top -25%",
                            scrub: true,
                            markers: false
                        }
                    }
                );
                tl.to(title, {
                    ease: 'none',
                    y: 0
                });
                tl.to(copy, {
                    ease: 'none',
                    y: 0
                }, "<");
                
            },
            initServiceItem(item){
                
                //item.style.border = '1px solid red'
                
                gsap.set(item, {
                    opacity: 0,
                    y: window.innerHeight * 0.5
                });

                let tl = gsap.timeline(
                    {
                        scrollTrigger: {
                            trigger: item,
                            start: "top 100%",
                            end: "top 50%",
                            scrub: true,
                            markers: false
                        }
                    }
                );
                tl.to(item, {
                    ease: 'none',
                    opacity: 1,
                    y: 0
                });
                
            },
            initAreasofexpertiseItem(item){
                
                //item.style.border = '1px solid red'
                
                const items = gsap.utils.toArray('.item', item);
                
                gsap.set(items, {
                    y: window.innerHeight * 0.5
                });

                let tl = gsap.timeline(
                    {
                        scrollTrigger: {
                            trigger: item,
                            start: "top 125%",
                            end: "top 25%",
                            scrub: true,
                            markers: false
                        }
                    }
                );
                tl.to(items, {
                    ease: 'none',
                    y: 0,
                    stagger: 0.1
                });
                
            },
            initSection(item){            
                
                const bghexcolor = item.getAttribute('data-bghexcolor'); 
                const logohexcolor = item.getAttribute('data-logohexcolor');                
                ScrollTrigger.create({
                    trigger: item,
                    start:'top 50%',
                    end:'bottom 50%',
                    onEnter: () => {
                        gsap.to('#background', {
                            backgroundColor: bghexcolor,
                            duration: 1,
                            overwrite: 'auto'
                        })
                        gsap.to('#logo svg path', {
                            fill: logohexcolor,
                            duration: 1,
                            overwrite: 'auto'
                        })
                    }, 
                    //onLeave: () => gsap.to('#background', {backgroundColor: 'white'}), 
                    //onLeaveBack: () => gsap.to('#background', {backgroundColor: 'white'}), 
                    onEnterBack: () => {
                        gsap.to('#background', {
                            backgroundColor: bghexcolor,
                            duration: 1,
                            overwrite: 'auto'
                        })
                        gsap.to('#logo svg path', {
                            fill: logohexcolor,
                            duration: 1,
                            overwrite: 'auto'
                        })
                    }, 
                    markers: false
                });
            }
        },
        mounted() {
            
            this.logo_height = Math.round((56/360 * (window.innerWidth - 80)) + 40);
            
            this.hero_title = this.$refs.hero_title
            
            const sections = gsap.utils.toArray('main .sections section');            
            sections.forEach(this.initSection);
            
            const statement_items = gsap.utils.toArray('main .sections section.statement .items .item');
            statement_items.forEach(this.initStatementItem);
            
            const services_items = gsap.utils.toArray('main .sections section.services .items');
            services_items.forEach(this.initServiceItem);
            
            const areasofexpertise_items = gsap.utils.toArray('main .sections section.areasofexpertise');
            areasofexpertise_items.forEach(this.initAreasofexpertiseItem);
            
            this.initHeroTitle();

        },
        unmounted() {}
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .sections {
        position: relative;
        margin: 0 auto;
        max-width: 1360px;
    }
    .sections section {
        position: relative;
    }
    .sections section + section {
        margin-top: 100px;
    }
    section.hero {
        min-height: 100vh;
        min-height: 100lvh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px 0;
    }
    section.hero h1 {
        position: relative;
        margin: 0;
        color: var(--color-purple);
        text-align: center;
        font-size: clamp(2rem, 2rem + 1.5625vw, 3.875rem);
        line-height: 0.8;
        letter-spacing: -0.2rem;
        opacity: 0;
    }
    
    section.statement {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media (min-width: 768px) {
        section.statement {
            min-height: 100vh;
            min-height: 100lvh;
        }
    }
    section.statement .items {
        padding: 0 40px;
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: repeat(12, 1fr);
        grid-auto-rows: auto;
        grid-row-gap: 80px;
        align-items: flex-start;
    }
    @media (min-width: 768px) {
        section.statement .items {
            grid-auto-flow: column;
            grid-column-gap: 40px;
            grid-row-gap: 0;
        }
    }
    section.statement .items .item {
        display: grid;
        grid-auto-flow: row;
        grid-row-gap: 20px;
    }
    @media (min-width: 768px) {
        section.statement .items .item {
            grid-row-gap: 20px;
        }
    }
    @media (min-width: 1024px) {
        section.statement .items .item {
            grid-row-gap: 40px;
        }
    }    
    @media (min-width: 768px) {
        section.statement .items .item:not(:nth-child(1)) {
            margin-top: -20px
        }
    }
    @media (min-width: 1024px) {
        section.statement .items .item:not(:nth-child(1)) {
            margin-top: -20px
        }
    }
    section.statement .items .item:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: span 12;
        grid-row-start: 1;
    }
    section.statement .items .item:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: span 12;
        grid-row-start: 2;
    }
    section.statement .items .item:nth-child(3) {
        grid-column-start: 1;
        grid-column-end: span 12;
        grid-row-start: 3;
    }
    @media (min-width: 768px) {
        section.statement .items .item:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: span 4;
        }
    }
    @media (min-width: 768px) {
        section.statement .items .item:nth-child(2) {
            grid-column-start: 5;
            grid-column-end: span 4;
        }
    }
    @media (min-width: 768px) {
        section.statement .items .item:nth-child(3) {
            grid-column-start: 9;
            grid-column-end: span 4;
        }
    }
    @media (min-width: 1024px) {
        section.statement .items .item:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: span 3;
        }
    }
    @media (min-width: 1024px) {
        section.statement .items .item:nth-child(2) {
            grid-column-start: 5;
            grid-column-end: span 3;
        }
    }
    @media (min-width: 1024px) {
        section.statement .items .item:nth-child(3) {
            grid-column-start: 9;
            grid-column-end: span 3;
        }
    }
    section.statement .items .item .title {
        align-self: flex-start;
    }
    section.statement .items .item .title h2 {
        color: var(--color-darkgrey);
        font-size: clamp(1rem, 1rem + 0.3125vw, 1.375rem);
        line-height: 1.0;
        margin: 0;
    }
    section.statement .items .item .copy {
        align-self: flex-start;
    }
    @media (min-width: 568px) {
        section.statement .items .item .copy {
            columns: 2;
            column-gap: 20px;
        }
    }
    @media (min-width: 768px) {
        section.statement .items .item .copy {
            columns: auto;
        }
    }
    section.statement .items .item .copy p {
        color: var(--color-darkgrey);
    }
    
    section.services {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media (min-width: 768px) {
        section.services {
            min-height: 100vh;
            min-height: 100lvh;
        }
    }
    section.services .items {
        position: relative;
        padding: 0 40px;
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: repeat(12, 1fr);
        grid-auto-rows: auto;
        grid-row-gap: 20px;
        align-items: flex-start;
        padding-top: 20px;
    }
    @media (min-width: 768px) {
        section.services .items {
            grid-auto-flow: column;
            grid-column-gap: 40px;
            grid-row-gap: 0;
        }
    }
    section.services .items:before {
        content: '';
        position: absolute;
        top: 0;
        right: 40px;
        left: 40px;
        height: 1px;
        background-color: var(--color-darkgrey);
    }
    section.services .items .item:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: span 12;
        grid-row-start: 1;
    }
    section.services .items .item:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: span 12;
        grid-row-start: 2;
    }
    @media (min-width: 768px) {
        section.services .items .item:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: span 4;
        }
    }
    @media (min-width: 768px) {
        section.services .items .item:nth-child(2) {
            grid-column-start: 5;
            grid-column-end: span 8;
            grid-row-start: 1;
        }
    }
    @media (min-width: 1024px) {
        section.services .items .item:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: span 3;
        }
    }
    @media (min-width: 1024px) {
        section.services .items .item:nth-child(2) {
            grid-column-start: 4;
            grid-column-end: span 9;
        }
    }
    section.services .items .item:nth-child(1) .title h2 {
        margin: 0;
    }
    @media (min-width: 568px) {
        section.services .items .item:nth-child(2) .copy {
            columns: 2;
            column-gap: 20px;
        }
    }
    @media (min-width: 768px) {
        section.services .items .item:nth-child(2) .copy {
            columns: 2;
            column-gap: 40px;
        }
    }
    @media (min-width: 1024px) {
        section.services .items .item:nth-child(2) .copy {
            columns: 3;
            column-gap: 40px;
        }
    }
    
    section.areasofexpertise {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    @media (min-width: 768px) {
        section.areasofexpertise {
            min-height: 100vh;
            min-height: 100lvh;
        }
    }
    section.areasofexpertise .items {
        position: relative;
        width: 100%;
        padding: 0 40px;
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: repeat(12, 1fr);
        grid-auto-rows: auto;
        grid-row-gap: 1px;
        align-items: flex-start;
        padding-top: 20px;
    }
    @media (min-width: 768px) {
        section.areasofexpertise .items {
            grid-auto-flow: column;
            grid-column-gap: 40px;
        }
    }
    section.areasofexpertise .items .item {
        position: relative;
        grid-column-start: 1;
        grid-column-end: span 12;
        background-color: var(--color-white);
        padding: 6px 20px;
        border-radius: 20px;
        cursor: pointer;
    }
    @media (min-width: 768px) {
        section.areasofexpertise .items .item {
            grid-column-start: 4;
            grid-column-end: span 9;
        }
    }
    section.areasofexpertise .items .item .title {
        position: relative;
        pointer-events: none;
        user-select: none;
        padding-right: 20px;
    }
    section.areasofexpertise .items .item .title svg {
        position: absolute;
        top: 50%;
        right: -10px;
        width: 22px;
        height: 22px;
        border: 1px solid var(--color-grey);
        border-radius: 50%;
        text-align: center;
        transform: translate3d(0, -50%, 0) rotate(45deg);
        transition: border 250ms ease, transform 100ms ease;
        transform-origin: center center;
    }
    @media (min-width: 768px) {
        section.areasofexpertise .items .item.collapsed:hover .title svg {
            border: 1px solid var(--color-grey);
        }
    }
    section.areasofexpertise .items .item.collapsed .title svg {
        border: 1px solid var(--color-darkgrey);
        transform: translate3d(0, -50%, 0) rotate(0deg);
        transition: border 250ms ease, transform 250ms ease;
    }
    
    section.areasofexpertise .items .item .title svg path {
        fill: var(--color-grey);
        transition: fill 250ms ease;
    }
    section.areasofexpertise .items .item.collapsed .title svg path {
        fill: var(--color-darkgrey);
        transition: fill 250ms ease;
    }
    @media (min-width: 768px) {
        section.areasofexpertise .items .item.collapsed:hover .title svg path {
            fill: var(--color-grey);
        }
    }
    section.areasofexpertise .items .item .title h2 {
        margin: 0;
    }
    section.areasofexpertise .items .item .copy {
        padding-top: 20px;
        padding-bottom: 20px;
        pointer-events: none;
        user-select: none;
    }
    section.areasofexpertise .items .item.collapsed .copy {
        display: none;
    }
    @media (min-width: 768px) {
        section.areasofexpertise .items .item .copy {
            columns: 3;
            column-gap: 40px;
        }
    }
    
    section.contact {
        position: relative;
        min-height: 100vh;
        min-height: 100lvh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 100;
    }
    section.contact h1 {
        position: relative;
        margin: 0;
        color: var(--color-purple);
        text-align: center;
        font-size: clamp(2rem, 2rem + 1.5625vw, 3.875rem);
        line-height: 0.8;
        letter-spacing: -0.2rem;
        transition: opacity 500ms cubic-bezier(0.645, 0.045, 0.355, 1) 500ms;
    }
    section.contact .buttons {
        position: relative;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        grid-row-gap: 1px;
    }
    section.contact .buttons .button a {
        position: relative;
        color: var(--color-darkgrey);
        text-decoration: none;
        background-color: var(--color-white);
        padding: 6px 60px 6px 20px;
        border-radius: 20px;
        cursor: pointer;
        display: flex;
    }
    section.contact .buttons .button a h2 {
        margin: 0;
    }
    section.contact .buttons .button a svg {
        position: absolute;
        top: 50%;
        right: 10px;
        width: 22px;
        height: 22px;
        border: 1px solid var(--color-darkgrey);
        border-radius: 50%;
        text-align: center;
        transform: translate3d(0, -50%, 0);
        transition: border 250ms ease;
        transform-origin: center center;
    }
    @media (min-width: 768px) {
        section.contact .buttons .button a:hover svg {
            border: 1px solid var(--color-grey);
        }
    }
    section.contact .buttons .button a svg path {
        fill: var(--color-darkgrey);
        transition: fill 250ms ease;
    }
    @media (min-width: 768px) {
        section.contact .buttons .button a:hover svg path {
            fill: var(--color-grey);
        }
    }
</style>