<template>
    <Scrolled />
    <Background />
    <Logo />
    <Header />
    <main>
        <router-view v-slot="{ Component, route }">
            <Transition name="fade" :duration="{ enter: 500, leave: 500 }"  mode="out-in">
                <component :is="Component" :key="route.path" />
            </Transition>
        </router-view>
    </main>
    <Footer />
</template>

<script>
    import Scrolled from './components/Scrolled.vue'
    import Background from './components/Background.vue'
    import Header from './components/Header.vue'
    import Logo from './components/Logo.vue'
    import Footer from './components/Footer.vue'
    
    import { useMouse } from './composables/mouse.js'
    import { useLenis } from './composables/lenis.js'
    import './composables/lenis.css'

    export default {
        name: 'App',
        data() {
            return {
                mouse: useMouse()
            }
        },
        components: {
            Scrolled,
            Background,
            Header,
            Logo,
            Footer
        },
        mounted() {
            useLenis()
            document.title = 'Duckert - Home'
        },
        beforeUnmount() {},
        methods: { }
    }

</script>

<style>
    
    :root {
        --color-purple: rgb(71, 77, 130);
        --color-grey: rgb(222, 222, 222);
        --color-darkgrey: rgb(46, 46, 46);
        --color-white: rgb(255, 255, 255);
        --scrolled_percentage: 0;
    }
    
    
    @font-face {
        font-family: 'TWKLausanne';
        src: url('./assets/webfonts/TWKLausanne-300.woff2') format('woff2'),
            url('./assets/webfonts/TWKLausanne-300.woff') format('woff');
        font-weight: 300;
        font-style: normal;
    }
    html {
        box-sizing: border-box;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }
    body {
        margin: 0;
        font-family: 'TWKLausanne', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;        
        font-size: clamp(0.75rem, 0.75rem + 0.1042vw, 0.875rem);
        line-height: 1.3;
    }
    h1, h2, h3, h4, h5 {
        font-weight: 400;
        margin-top: 0;
    }
    p:first-child {
        margin-top: 0;
    }
    p:last-child {
        margin-bottom: 0;
    }
    
    /* main */
    main {
        position: relative;
        z-index: 4;
    }
    
    /* inner */
    .inner {
        position: relative;
        margin: 0 auto;
        padding: 0 20px;
        max-width: 960px;
    }
    
    /* we will explain what these classes do next! */
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
    
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
    
</style>