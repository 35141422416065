import { reactive } from 'vue'

export const store = reactive({
    logohexcolor: '#dddddd',
    bghexcolor: '#2e2e2e',
    changeColor(hexcolor) {
        this.bghexcolor = hexcolor
        console.log(this.bghexcolor);
    },
    changeLogoColor(hexcolor) {
        this.logohexcolor = hexcolor
        console.log(this.logohexcolor);
    }
})