<template>
    <header>
        <div class="logo">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="360px" height="56px" viewBox="0 0 360 56" style="enable-background:new 0 0 360 56;" xml:space="preserve">
                <path shape-rendering="geometricPrecision" class="st0" d="M50.7,27.8c0,15.7-8.3,27-26.9,27H0V54c8,0,8.6-0.7,8.6-7V8.6c0-6.3-0.5-7-8.6-7V0.8h23.8
                C42.3,0.8,50.7,12.1,50.7,27.8z M44.9,27.8c0-16.2-8.3-26.1-22.8-26.1c-8,0-8.6,0.7-8.6,6.9V47c0,6.2,0.5,6.9,8.6,6.9
                C36.8,53.9,44.9,43.8,44.9,27.8z"/>
                <path shape-rendering="geometricPrecision" class="st1" d="M56.8,35.8V8.6c0-6.3-0.5-7-8.6-7V0.8h22.2v0.8c-8,0-8.6,0.7-8.6,7v28.2c0,10,5.3,17.8,15.8,17.8
                c9.7,0,16.9-5.6,16.9-17.8V8.6c0-6.3-0.5-7-9-7V0.8h19v0.8c-8.1,0-8.6,0.7-8.6,7v28.2c0,12.1-6.4,18.8-18.3,18.8
                C64.9,55.6,56.8,48.9,56.8,35.8z"/>
                <path shape-rendering="geometricPrecision" class="st2" d="M102.3,27.2c0-16.8,10.1-27.2,25.2-27.2c11.3,0,18,6.2,21.1,10.3l-13.8,14l-0.5-0.7c11-12.8,6-22.7-6.3-22.7
                c-11.6,0-19.8,11.6-19.8,26.2c0,16.6,7.7,27.5,20.2,27.5c11.2,0,17.2-8,20.8-23.4l3.1,6.8c-4.4,10.6-11.7,17.5-24.6,17.5
                C113.2,55.6,102.3,44.8,102.3,27.2z"/>
                <path shape-rendering="geometricPrecision" class="st3" d="M208.2,54v0.8h-24.9V54c9,0,10.4-0.5,6-7l-16.6-24.6l-8.1,6.9V47c0,6.3,0.5,7,8.6,7v0.8h-22.2V54
                c8,0,8.6-0.7,8.6-7V8.6c0-6.3-0.6-7-8.6-7V0.8h22.2v0.8c-8,0-8.6,0.7-8.6,7v19.2l22.6-19.2c6.8-5.7,5.2-7-4-7V0.8h22.1v0.8
                c-7.3,0-9.4,1.2-16.4,7l-12.8,10.9L194.8,47C199.1,53.5,202,54,208.2,54z"/>
                <path shape-rendering="geometricPrecision" class="st4" d="M254.2,35.1l-5.6,19.7h-42.8V54c8,0,8.6-0.7,8.6-7V8.6c0-6.3-0.5-7-8.6-7V0.8h42.1l5.1,15.4H252
                c-3.6-10.7-8.3-14.5-18.4-14.5H228c-8,0-8.6,0.7-8.6,6.9v18.1h7.8c9.2,0,12.6-0.6,16.3-10.5h1l-3.9,11l4.2,11.8h-1
                c-3.8-10.7-7.3-11.3-16.6-11.3h-7.8V47c0,6.2,0.5,6.9,8.6,6.9h4.9c12.2,0,15.6-5.5,20.4-18.8H254.2z"/>
                <path shape-rendering="geometricPrecision" class="st5" d="M255.3,54c8,0,8.6-0.7,8.6-7V8.6c0-6.4-0.5-7-8.6-7V0.8h26.1c14.1,0,20.8,5.1,20.8,13.3c0,7.5-7,12.2-17.1,12.7
                v0.5c7.3,1.8,12.3,2.1,13.2,10.8c1.5,14.3,3.2,15.9,14.2,15.9v0.8h-20.8V54h5.5c-1.8-3.6-2.8-8.2-3.3-14.4
                c-0.6-7.6-6.7-11.1-22-14.7v-0.8c4,0.8,7.8,1.4,11.2,1.4c7.8,0,13.4-3.8,13.4-11.4c0-7.9-5.7-12.2-15-12.2h-3.9
                c-8,0-8.6,0.7-8.6,6.7V47c0,6.3,0.5,7,8.6,7v0.8h-22.2V54z"/>
                <path shape-rendering="geometricPrecision" class="st6" d="M318.8,54c11.9,0,12.4-0.7,12.4-7V8.6c0-5.8-0.5-6.9-7.2-6.9c-6,0-9.7,2.6-15.8,16.8h-0.8l5.5-17.8h41.6
                l5.5,17.8h-0.8c-6.1-14.2-9.8-16.8-15.8-16.8c-6.7,0-7.2,1.1-7.2,6.9V47c0,6.3,0.5,7,12.4,7v0.8h-29.9V54z"/>
            </svg>
        </div>
        <nav>
            <ul>
                <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/about">About</router-link></li>
            </ul>
        </nav>
    </header>
</template>

<script>
    export default {
        name: 'PageHeader',
        
        // Properties returned from data() become reactive state
        // and will be exposed on `this`.
        data() {
            return {}
        },
        
        props: {},
        
        // Methods are functions that mutate state and trigger updates.
        // They can be bound as event listeners in templates.
        methods: {},
        
        // Lifecycle hooks are called at different stages
        // of a component's lifecycle.
        // This function will be called when the component is mounted.
        mounted() {}
        
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    header {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 20px 40px;
        z-index: 2;
        display: none;
    } 
    header .logo {
        flex: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        pointer-events: none;
        user-select: none;
    }
    header .logo svg {
        position: relative;
        width: 100%;
        height: auto;
        display: block;
        transform: translate3d(0, 0, 0);
        transition: transform 1000ms cubic-bezier(0.645, 0.045, 0.355, 1) 500ms;
        will-change: transform;
        transform-origin: top center;
        max-width: 360px;
    }
    body.below_breakpoint header .logo svg {
        position: relative;
        transform: translate3d(0, -65px, 0);
        transition: transform 1000ms cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    header .logo svg path {
        position: relative;
        fill: #dddddd;
    }
    header nav {
        display: none;
    }
    header nav ul {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        column-gap: 10px;
        margin: 10px 0 0 0;
        padding: 0;
    }    
    header nav ul a {
        color: black;
        text-decoration: none;
    }    
    html.lenis-scrolling header nav ul a {
        pointer-events: none;
    }  
    header nav ul a[aria-current="page"] {
        text-decoration: underline;
    }
</style>